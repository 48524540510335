import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { url } from '../env';
import powerby from '../assets/img/footerlogo.png';
import { ArrowClick, Handlefile, BenefitsOfHavingLiveChat } from './Svg';
import homeicon from '../assets/img/homeicon.png';
import chater from '../assets/img/avatar-2.jpg';
const backgroundImage = require('../assets/img/background.png');

function Chatbot() {
    const chatRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isChatOpen, setIsChatOpen] = useState(true);
    const [inputValue, setInputValue] = useState('');
    const [showEmojiBox, setShowEmojiBox] = useState(false);
    const [showFileBox, setShowFileBox] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    function generateSessionId() {
        return '_' + Math.random().toString(36).substr(2, 9);  // Create a simple random session ID
    }

    function getSessionId() {
        let sessionId = localStorage.getItem('session_id');
        if (!sessionId) {
            sessionId = generateSessionId();
            localStorage.setItem('session_id', sessionId);  // Save session ID in localStorage
            console.log('New session created:', sessionId);
        } else {
            console.log('Existing session ID:', sessionId);
        }
        return sessionId;
    }
    function openElement() {
        const element = $(chatRef.current);
        setIsChatOpen(true);
        const textInput = element.find('.text-box');
        element.find('>i').hide();
        element.addClass('expand');
        element.find('.chat').addClass('enter');
        textInput.keydown(onEnterPress).prop('disabled', false).focus();
        element.off('click', openElement);
        element.find('.header button').on('click', closeElement);
        element.find('#sendMessage').on('click', sendNewMessage);
        element.find('.messages').scrollTop(element.find('.messages').prop('scrollHeight'));
    }

    function closeElement() {
        const element = $(chatRef.current);
        element.find('.chat').removeClass('enter').hide();
        element.find('#comments').show();
        element.removeClass('expand');
        element.find('.header button').off('click');
        element.find('#sendMessage').off('click');
        element.find('.home-tab').addClass('chat');
        element.find('.text-box').off('keydown').prop('disabled', true).blur();
        setIsChatOpen(false);
        element.on('click', openElement);
        setTimeout(() => {
            element.find('.chat').removeClass('enter home-tab tabs').show();
        }, 500);
    }

    function createUUID() {
        let s = [];
        const hexDigits = '0123456789abcdef';
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
    }
    useEffect(() => {
        const element = $(chatRef.current);
        const myStorage = localStorage;

        if (!myStorage.getItem('chatID')) {
            myStorage.setItem('chatID', createUUID());
        }

        setTimeout(() => {
            element.addClass('enter');
        }, 1000);
        element.on('click', openElement);

        const messagesArea = element.find('.messages');
        return () => {
            element.off('click', openElement);
            messagesArea.off('mouseenter');
            messagesArea.off('mouseleave');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, isChatOpen]);

    useEffect(() => {
        document.body.style.backgroundImage = `url(${backgroundImage})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundRepeat = 'no-repeat';
        if (messages.length > 0) {
            const messagesContainer = chatRef.current.querySelector('.messages');
            if (messagesContainer) {
                messagesContainer.scrollTop = messagesContainer.scrollHeight;
            }
        }
        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
            document.body.style.backgroundRepeat = '';
        }
    }, [messages]);

    function sendNewMessage() {
        const newMessage = inputValue.trim();

        if (!newMessage) return;

        setMessages(prevMessages => [
            ...prevMessages,
            { type: 'self', text: newMessage }
        ]);

        setInputValue('');
        const payload = {
            session_id: getSessionId(),
            message: newMessage
        };
        setLoading(true);
        console.log('meeage data', payload);
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payload)
        })
            .then(response => response.json())
            .then(data => {
                setLoading(false);
                const newMessages = [{
                    type: 'other',
                    text: data.response
                }];
                setMessages(prevMessages => [...prevMessages, ...newMessages]);
            })
            .catch(error => {
                setLoading(false);
                console.error('Error:', error);
            });
    }
    const handleEmojiClick = (emoji) => {
        setInputValue(prev => prev + emoji);
        setShowEmojiBox(false);
    };
    function onEnterPress(event) {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            sendNewMessage();
        }
    }
    const handleFileClick = () => {
        setShowFileBox(prev => !prev);
        setShowEmojiBox(false);
    };
    const handleEmojiButtonClick = () => {
        setShowEmojiBox(prev => !prev);
        setShowFileBox(false);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setMessages((prevMessages) => [
                ...prevMessages,
                { type: 'self', text: file.name }
            ]);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('session_id', getSessionId());
            setLoading(true);
            fetch(`${url}/upload`, {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    setLoading(false);
                    setMessages((prevMessages) => [
                        ...prevMessages,
                        { type: 'other', text: `File uploaded: ${data.fileName}` }
                    ]);
                })
                .catch(error => {
                    setLoading(false);
                    console.error('File upload error:', error);
                });
        }
    };
    const totalPages = 2;
    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(prev => prev + 1);
        }
        if (messages.length === 0) {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                setMessages(prevMessages => {
                    if (prevMessages.length === 0) {
                        return [...prevMessages, { type: 'other', text: "Hey there! I'm Qubit, your friendly assistant at DevPandas! What's your name? I'd love to get to know you and help you out!" }];
                    }
                    return prevMessages;
                });
            }, 1000);
        }
    };
    const handlePrev = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    return (
        <>
            <div className={`floating-chat`} ref={chatRef} draggable="false">
                <img id='comments' src={homeicon} alt="" aria-hidden="true" onClick={openElement} style={{ width: '40px', height: 'auto' }} />
                <div className={`chat`}>
                    {currentPage > 1 && (
                        <>
                            <div className="header">
                                <div className='left-btn-wrap'>
                                    <span className='back-arrow' onClick={handlePrev}>
                                        <i className="fa-solid fa-arrow-left"></i>
                                    </span>
                                    <span className='dropdown' id='dropdown' onClick={toggleDropdown}>
                                        <i className="fa-solid fa-ellipsis"></i>
                                    </span>
                                    {isOpen && (
                                        <div className="dropdown-menu">
                                            <button>
                                                <i className="fa-regular fa-envelope"></i> Send transcript
                                            </button>
                                            <button>
                                                <i className="fa-solid fa-mobile-screen-button"></i> Move chat to mobile
                                            </button>
                                            <button>
                                                <i className="fa-solid fa-volume-high"></i> Sound
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <span className="title">DevPandas Assistant</span>
                                <button onClick={closeElement}>
                                    <i className="fa fa-times" aria-hidden="true"></i>
                                </button>
                            </div>
                            <ul className="messages">
                                {messages.map((message, index) => (
                                    <li key={index} className={message.type}>
                                        {message.text}
                                    </li>
                                ))}
                                {loading && <li className="other">
                                    <div className="loading">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </li>}
                            </ul>
                            <div className='btn_wrap'>
                                <div className="scroll-container"></div>
                            </div>
                            <div className="footer">
                                <input
                                    type="text"
                                    className="text-box"
                                    value={inputValue}
                                    onChange={(e) => setInputValue(e.target.value)}
                                    onKeyDown={onEnterPress}
                                    placeholder="Enter here...."
                                />
                                <button id="fileadd" onClick={handleFileClick}>
                                    <Handlefile />
                                </button>
                                {showFileBox && (
                                    <div className="file-box">
                                        <button onClick={() => document.getElementById('fileInput').click()} disabled>
                                            <i className="fa-solid fa-file-circle-plus"></i> Send a file
                                        </button>
                                        <button onClick={() => document.getElementById('fileInput').click()} disabled>
                                            <i className="fa-solid fa-images"></i> Attach a screenshot
                                        </button>
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            onChange={(e) => handleFileChange(e)}
                                            accept="image/*"
                                        />
                                    </div>
                                )}
                                <button id="emojibutton" onClick={handleEmojiButtonClick}>
                                    <i className="fa-regular fa-face-smile"></i>
                                </button>
                                {showEmojiBox && (
                                    <div className="emoji-box">
                                        <button onClick={() => handleEmojiClick('😀')}>😀</button>
                                        <button onClick={() => handleEmojiClick('😃')}>😃</button>
                                        <button onClick={() => handleEmojiClick('😄')}>😄</button>
                                        <button onClick={() => handleEmojiClick('😅')}>😅</button>
                                        <button onClick={() => handleEmojiClick('😂')}>😂</button>
                                        <button onClick={() => handleEmojiClick('🤣')}>🤣</button>
                                        <button onClick={() => handleEmojiClick('😊')}>😊</button>
                                        <button onClick={() => handleEmojiClick('😇')}>😇</button>
                                        <button onClick={() => handleEmojiClick('😌')}>😌</button>
                                        <button onClick={() => handleEmojiClick('😍')}>😍</button>
                                        <button onClick={() => handleEmojiClick('🥰')}>🥰</button>
                                        <button onClick={() => handleEmojiClick('😘')}>😘</button>
                                        <button onClick={() => handleEmojiClick('😗')}>😗</button>
                                        <button onClick={() => handleEmojiClick('😙')}>😙</button>
                                        <button onClick={() => handleEmojiClick('😚')}>😚</button>
                                        <button onClick={() => handleEmojiClick('😋')}>😋</button>
                                        <button onClick={() => handleEmojiClick('😛')}>😛</button>
                                        <button onClick={() => handleEmojiClick('😝')}>😝</button>
                                        <button onClick={() => handleEmojiClick('😜')}>😜</button>
                                        <button onClick={() => handleEmojiClick('🤪')}>🤪</button>
                                        <button onClick={() => handleEmojiClick('🤨')}>🤨</button>
                                        <button onClick={() => handleEmojiClick('🧐')}>🧐</button>
                                        <button onClick={() => handleEmojiClick('🤓')}>🤓</button>
                                        <button onClick={() => handleEmojiClick('😎')}>😎</button>
                                        <button onClick={() => handleEmojiClick('🤩')}>🤩</button>
                                        <button onClick={() => handleEmojiClick('🥳')}>🥳</button>
                                        <button onClick={() => handleEmojiClick('😩')}>😩</button>
                                        <button onClick={() => handleEmojiClick('😭')}>😭</button>
                                        <button onClick={() => handleEmojiClick('😤')}>😤</button>
                                        <button onClick={() => handleEmojiClick('😠')}>😠</button>
                                        <button onClick={() => handleEmojiClick('😡')}>😡</button>
                                        <button onClick={() => handleEmojiClick('🤯')}>🤯</button>
                                        <button onClick={() => handleEmojiClick('😳')}>😳</button>
                                        <button onClick={() => handleEmojiClick('🥵')}>🥵</button>
                                        <button onClick={() => handleEmojiClick('🥶')}>🥶</button>
                                        <button onClick={() => handleEmojiClick('😱')}>😱</button>
                                        <button onClick={() => handleEmojiClick('😨')}>😨</button>
                                        <button onClick={() => handleEmojiClick('😰')}>😰</button>
                                    </div>
                                )}
                                <button id="sendMessage" onClick={sendNewMessage} disabled={!inputValue.trim()}>
                                    <ArrowClick />
                                </button>
                            </div>
                            <p className='copyright'>Powered by <img src={powerby} alt="" /> </p>
                        </>
                    )}
                    {currentPage < totalPages && (
                        <>
                            <div className="header-two">
                                <button style={{ visibility: 'hidden' }}>Go to Chat</button>
                                <span className="title" style={{ visibility: 'hidden' }}><i className="fa-solid fa-message"></i></span>
                                <button onClick={closeElement}>
                                    <i className="fa-regular fa-window-minimize"></i>
                                </button>
                            </div>
                            <ul className="messages-two">
                                <div className='home-icon-wrap'>
                                    <img className='home-icon' src={homeicon} alt="" />
                                </div>
                                <h1>Chat with us!</h1>
                                <li>
                                    <div className='first-list'>
                                        <img src={chater} alt="" />
                                        <span className='online-icon'></span>
                                        <span>
                                            <p>DevPandas Bot</p>
                                            <p>Hello there!</p>
                                        </span>
                                    </div>
                                    <button className='chat-button' onClick={handleNext}>Chat now
                                        <ArrowClick />
                                    </button>
                                </li>
                                <a href="https://www.devpandas.co/services" target='_blank' rel="noopener noreferrer">
                                    <li>🎓 Services
                                        <BenefitsOfHavingLiveChat />
                                    </li>
                                </a>
                                <a href="https://www.devpandas.co/resources" target='_blank' rel="noopener noreferrer">
                                    <li>🎉 Resources
                                        <BenefitsOfHavingLiveChat />
                                    </li>
                                </a>
                                <a href="https://www.devpandas.co/contact" target='_blank' rel="noopener noreferrer">
                                    <li>
                                        💬 Contact us
                                        <BenefitsOfHavingLiveChat />
                                    </li>
                                </a>
                            </ul>
                            <div className="footer-two">
                                <button>
                                    <i className="fa-solid fa-house"></i>
                                    <span>Home</span>
                                </button>
                                <button onClick={handleNext}>
                                    <i className="fa-regular fa-comments"></i>
                                    <span>Chat</span>
                                </button>
                            </div>
                            <p className='copyright'>Powered by <img src={powerby} alt="" /> </p>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Chatbot;